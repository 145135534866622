import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql } from "gatsby";
// import Img from "gatsby-image"
import Title from "../components/title";

const Contact = ({ data }) => {
  const pageData = {
    titleClass: "contact",
    title: "お問い合せ",
    discription: "",
  };
  const seodata = {
    title: "お問い合わせ アヴィエラン株式会社　物流機器事業部",
    seo: {
      description: "物流の現場で使用されるスリングベルト・ラッシングベルトなどの資材の製造、販売をしております。",
    },
    image: {
      url: data.datoCmsImage.topimage[21].url,
    },
    slug: "contact",
  };
  // const newsData = data.allDatoCmsNews.nodes

  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="contact_">
        <div className="tel_contact">
          <p>
            <span>お電話でのお問い合わせ</span>
          </p>
          <p>052-701-6711</p>
          <p>平日：10時～16時</p>
          <p>土・日・祝日は基本的にお休みとさせて頂きます。</p>
        </div>
        <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/thanks/">
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <div className="form-group">
            <label>
              <span>
                会社名<b>※</b>
              </span>
              <input type="text" className="form-control" id="company" name="company" placeholder="会社名" maxLength="30" minLength="2" required />
            </label>

            <label>
              <span>部署名</span>
              <input type="text" className="form-control" id="div-name" name="div-name" placeholder="部署名" maxLength="30" minLength="2" />
            </label>

            <label>
              <span>
                お名前<b>※</b>
              </span>
              <input type="text" className="form-control" id="name" name="name" placeholder="お名前" maxLength="30" minLength="2" required autoComplete="name" />
            </label>

            <label>
              <span>
                メールアドレス<b>※</b>
              </span>
              <input type="email" className="form-control" id="email" name="email" placeholder="" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autoComplete="email" />
            </label>

            <label>
              <span>電話番号</span>
              <input type="text" className="form-control" id="telnum" name="telnum" />
            </label>

            <label>
              <span>
                お問い合わせ内容<b>※</b>
              </span>
              <textarea className="form-control" id="contact" name="content" rows="10" required></textarea>
            </label>
          </div>

          <div className="form-btn">
            <button type="submit">送信</button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
